/**
 * CkDivider Component
 * 
 * The CkDivider component is an extendable wrapper around the Divider component from AntDesign.
 * It accepts all properties of the AntDesign Divider as well as additional properties.
 * It also includes some custom styles through a "ck-divider" CSS class.
 *
 * @component
 * @example
 * // This is how you can use the CkDivider with children and custom className
 * <CkDivider className="myCustomClass">I am a child</CkDivider>
 *
 * @extends {DividerProps}
 * 
 * @property {string} className - Optional: Class name for the component from parent
 * @property {ReactNode} children - Optional: Child elements to insert into divider
 */
import React, { FC, ReactNode } from "react";
import { DividerProps, Divider } from "antd";
import "./styles.css";

export interface CkDividerProps extends DividerProps {
  /**
   * Optional: Class name for the component from parent
   */
  className?: string;
  /**
   * Optional: Child elements to insert into divider
   */
  children?: ReactNode;
}

/**
 * Extends AntD Divider props
 * See full doc on https://4x.ant.design/components/divider/
 */
const CkDivider: FC<CkDividerProps> = ({ className, children, ...props }) => (
  <Divider
    className={["ck-divider", ...(className ? [className] : [])].join(" ")}
    {...props}
  >
    {children && children}
  </Divider>
);

export default CkDivider;
